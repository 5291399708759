import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import user from "./modules/user";

Vue.use(Vuex);

const userState = createPersistedState({
  key: "user_info",
});

export default new Vuex.Store({
  modules: {
    user,
  },
  plugins: [userState],
});
