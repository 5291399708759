const SET_USER_INFO = "SET_USER_INFO";

const state = {
  userInfo: {}, // 用户信息
};

const mutations = {
  [SET_USER_INFO](state, data) {
    state.userInfo = data;
  },
};

const actions = {
  async setUserInfo({ commit }, params) {
    commit(SET_USER_INFO, params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
