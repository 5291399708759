<template>
  <div id="app">
    <Header @handleMenu="handleMenu" />
    <div class="menu-box" @click.self="closeMenu" v-if="showMenu">
      <div class="menu-content">
        <div
          class="menu-item"
          v-for="menu in menus"
          :class="{ active: currentMenu === menu.key }"
          @click="handleMenuChange(menu)"
        >
          {{ menu.name }}
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import Header from "@/components/Header";
export default {
  name: "HomeView",
  components: {
    Header,
  },
  data() {
    return {
      showMenu: false,
      menus: [
        { key: "home", name: "HOME", path: "/" },
        { key: "museum", name: "MUSEUM", path: "/museum" },
      ],
    };
  },
  computed: {
    currentMenu() {
      return this.$route.name;
    },
  },
  methods: {
    handleMenu() {
      this.showMenu = true;
    },
    closeMenu() {
      this.showMenu = false;
    },
    handleMenuChange(menu) {
      this.$router.push(menu.path);
      this.closeMenu();
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  border: 0;
}
html,
body,
#app {
  position: relative;
  height: 100%;
}
body {
  font-family: "Montserrat";
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding: 0;
  background: #000;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #363535;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #383636;
}

.menu-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  .menu-content {
    background: #3f3f3f;
    width: 320px;
    height: 100%;
    overflow: auto;
    padding-top: 60px;
    animation: showmenu 0.3s linear;
  }
  @keyframes showmenu {
    from {
      width: 0;
    }
    to {
      width: 320px;
    }
  }
  .menu-item {
    padding: 30px 50px;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 2px;
    color: #616161;
    cursor: pointer;
    &.active {
      color: #fff;
    }
  }
}
</style>
