<template>
  <div class="header_outer">
    <div class="header">
      <div class="menu_box">
        <img src="@img/menu.png" class="menu" @click="handleMenuclick" />
      </div>

      <div class="logo" @click="$router.push('/')">CVN Lab</div>

      <div class="header_nav">
        <a href="https://opensea.io/" target="_blank"
          ><img class="app_icon" src="@img/opensea.png"
        /></a>
        <a href="https://twitter.com/" target="_blank"
          ><img class="app_icon" src="@img/twitter.png"
        /></a>
        <div class="user_address" v-if="address">{{ address }}</div>
        <div class="wallet_btn" v-else @click="handleWalletConnect">
          Connect Wallet
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { walletWeb3modal } from "@u/wallet_web3modal";
export default {
  name: "Header",
  data() {
    return {
      menus: [
        { key: "containter", name: "CVN Lab" },
        { key: "museum", name: "Museum" },
      ],
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    currentMenu() {
      return this.$route.name;
    },
    address() {
      let w_address = this.userInfo?.wallet_address;
      if (w_address) {
        w_address = w_address.replace(/(.{6}).*(.{4})/, "$1...$2");
      }
      return w_address;
    },
  },
  methods: {
    async handleWalletConnect() {
      try {
        await walletWeb3modal.onConnect();
      } catch (e) {
        console.log(e);
      }
    },
    handleMenuclick() {
      this.$emit("handleMenu");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header_outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #000;
  // background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(40px);
}
.header {
  height: 80px;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1710px;
  margin: 0 auto;
  color: #fff;
  position: relative;
  .logo {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 40px;
    font-family: Impact;
    letter-spacing: 2px;
    // margin-left: -30px;
  }
  .app_icon {
    margin-right: 60px;
  }
  .wallet_btn {
    width: 220px;
    height: 40px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ffffff;
    border-radius: 40px;
  }
  .user_address {
    height: 40px;
    color: #000;
    font-size: 20px;
    line-height: 40px;
    width: 220px;
    text-align: center;
    background: url("@img/address_bg.png") no-repeat;
  }
  .header_nav {
    display: flex;
    align-items: center;
  }

  .menu_box {
    display: flex;
    font-size: 22px;
  }
  .menu {
    margin-right: 60px;
    cursor: pointer;
    &.active {
      color: #ffa529;
    }
  }
}
</style>
