/**
 * localStorage & sessionStorage
 * Author: Thompson
 */
import { isNotEmpty } from "./tools";

export function setLocalStorage(name, val) {
  if (isNotEmpty(val)) {
    window.localStorage.setItem(name, val);
  }
}

export function getLocalStorage(name) {
  return window.localStorage.getItem(name);
}

export function removeLocalStorage(name) {
  window.localStorage.removeItem(name);
}

export function removeAllStorage() {
  window.localStorage.clear();
}

export function setSessionStorage(name, val) {
  if (isNotEmpty(val)) {
    window.sessionStorage.setItem(name, val);
  }
}

export function getSessionStorage(name) {
  return window.sessionStorage.getItem(name);
}

export function removeSessionStorage(name) {
  window.sessionStorage.removeItem(name);
}
