<template>
  <div class="home">
    <img class="moon" src="@img/moon.png" />
    <swiper :options="swiperOption">
      <swiper-slide><img src="@img/swiper01.png" /></swiper-slide>
      <swiper-slide><img src="@img/swiper02.png" /></swiper-slide>
      <swiper-slide><img src="@img/swiper03.png" /></swiper-slide>
      <swiper-slide><img src="@img/swiper04.png" /></swiper-slide>
      <swiper-slide><img src="@img/swiper05.png" /></swiper-slide>
      <swiper-slide><img src="@img/swiper06.png" /></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
// 引入轮播插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "HomeView",
  components: {
    swiper,
    swiperSlide,
  },
  mounted() {
    // walletWeb3modal.onConnect();
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  methods: {
    onSwiper() {},
    onSlideChange() {},
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
}
.swiper-container {
  width: 100%;
  height: calc(100vh - 80px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
::v-deep {
  .swiper-pagination-bullet {
    background: rgba(215, 140, 27, 0.5);
    opacity: 1;
    width: 12px;
    height: 12px;
    &.swiper-pagination-bullet-active {
      background: radial-gradient(66% 66% at 35% 33%, #d78c1b 0%, #fffac6 100%);
    }
  }
  .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 20px 0;
  }
}
.moon {
  position: fixed;
  z-index: 4;
  top: 200px;
  left: 3%;
}
</style>
